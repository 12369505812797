<template>
    <GLText
        ref="refText"
        :text="text"
        :layer="layer"
        :options="textOptions"
        @sync="onTextSync"
    ></GLText>
</template>

<script setup>
    import { Mesh } from 'three';
    import { computed, onMounted, reactive, ref, watch, watchEffect } from 'vue';

    import { simpleMaterial, simplePlaneGeometry } from '@resn/gozer-three';
    import { useThreeObject } from '@resn/gozer-vue';
    import { gsap } from '@resn/gsap';

    import { HORIZONTAL, IN, IN_OUT, OUT, VERTICAL } from './constants';
    import GLText from '~/components/gl/GLText.vue';
    import { IN_DUR, LAYER_BG, OUT_DUR, OUT_START, TOTAL_DUR } from '~/core/constants';

    const props = defineProps({
        debug: { default: false },
        layer: { default: LAYER_BG },
        text: { default: '' },
        bounds: { default: { width: 1, height: 1 } },
        position: { default: { x: 0, y: 0 } },
        sign: { default: 1 },
        mask: { default: false },
        color: { default: '#ffffff' },
        opacity: { default: 1 },
    });

    const refText = ref(null);

    const fontSize = computed(() => props.bounds.height * 1.2);

    const textOptions = reactive({
        fontSize,
        color: props.color,
        anchorX: props.sign === 0 ? 'center' : props.sign > 0 ? 'left' : 'right',
        anchorY: 'center',
        opacity: props.opacity,
    });

    const { object } = useThreeObject(null, { name: 'Line' });
    const { object: innerObject } = useThreeObject(null, {
        name: 'LineInner',
        addToParent: false,
    });

    const meshDebug = new Mesh(simplePlaneGeometry, simpleMaterial('red', true));
    meshDebug.layers.set(props.layer);
    meshDebug.material.opacity = 0.1;

    object.add(innerObject);
    innerObject.add(meshDebug);

    onMounted(() => {
        const { material } = refText.value.mesh;
        material.defines.USE_MASK = props.mask ? 1 : 0;
        material.needsUpdate = true;
    });

    const onTextSync = () => {
        updateLetters();
        setBounds();
    };
    const updateLetters = () => refText.value.updateLetters();

    const getAnimation = ({ delay = 0, direction = HORIZONTAL, type = IN_OUT } = {}) => {
        const tl =
            direction === HORIZONTAL
                ? getAnimationX({ delay, type })
                : getAnimationY({ delay, type });
        return tl;
    };

    const show = ({ delay = 0, direction = HORIZONTAL } = {}) => {
        return getAnimation({ delay, direction, type: IN_OUT });
    };

    const getAnimationX = ({ delay = 0, type = IN_OUT } = {}) => {
        const { object, realSize, letters } = refText.value;
        const { bounds, sign } = props;

        const ratio = 800 / bounds.height;

        const xFr = bounds.width * sign;
        const xTo = -bounds.width * sign;

        const ambDist = realSize.width * 0.225 * ratio * sign;
        const ambDur = TOTAL_DUR * 1.1;

        const letterDist = bounds.width * 0.2;
        const lettersPosArr = letters.map((letter) => letter.position);

        const tl = gsap
            .timeline({ delay, onUpdate: updateLetters })
            .fromTo(
                innerObject.position,
                { x: 0 },
                { x: -ambDist, duration: ambDur, ease: 'none' },
                0
            )
            .fromTo(object.position, { x: xFr }, { x: 0, duration: IN_DUR, ease: 'power3.out' }, 0)
            .to(object.position, { x: xTo, duration: OUT_DUR, ease: 'power3.in' }, OUT_START)
            .fromTo(
                letters,
                { alpha: 0 },
                {
                    alpha: 1,
                    duration: 0.2,
                    ease: 'sine.out',
                    stagger: { each: 0.02, from: sign > 0 ? 'start' : 'end' },
                },
                0
            )
            .fromTo(
                lettersPosArr,
                { x: letterDist * sign },
                {
                    x: 0,
                    duration: 1,
                    ease: 'power2.out',
                    stagger: { each: 0.02, from: sign > 0 ? 'start' : 'end' },
                },
                0
            )
            .to(
                lettersPosArr,
                {
                    x: letterDist * -sign,
                    duration: 0.7,
                    ease: 'power2.in',
                    stagger: { each: 0.02, from: sign > 0 ? 'start' : 'end' },
                },
                OUT_START
            )
            .set(letters, { alpha: 0 });

        return tl;
    };

    const getAnimationY = ({ delay = 0, type = IN_OUT } = {}) => {
        const { bounds } = props;

        const { letters } = refText.value;
        const letterDist = bounds.height * 2;
        const lettersPosArr = letters.map((letter) => letter.position);

        const tl = gsap.timeline({ delay, onUpdate: updateLetters });
        if (type == IN_OUT || type == IN)
            tl.fromTo(
                lettersPosArr,
                { y: -letterDist },
                {
                    y: 0,
                    duration: 0.9,
                    ease: 'power3.out',
                    stagger: { each: 0.04 },
                },
                0
            ).set(letters, { alpha: 1 }, 0);
        if (type === IN_OUT || type == OUT)
            tl.to(
                lettersPosArr,
                {
                    y: -letterDist,
                    duration: 0.7,
                    ease: 'power4.in',
                    stagger: { each: 0.04, from: 'end' },
                },
                type == OUT ? 0 : OUT_START
            ).set(letters, { alpha: 0 });

        return tl;
    };

    const setPosition = () => {
        const { x, y } = props.position;
        object.position.set(x, y, 0);
    };

    const setBounds = () => {
        if (refText.value) {
            const { realSize } = refText.value;
            const { height } = props.bounds;
            const bgHeight = height * 0.95;

            meshDebug.scale.set(realSize.width, bgHeight, 1);
            meshDebug.position.set((realSize.width / 2) * props.sign, -bgHeight / 2, 0);
        }
    };

    const setDebug = () => (meshDebug.visible = props.debug);

    watchEffect(setPosition);
    watchEffect(setBounds);
    watchEffect(setDebug);
    watch(
        () => props.color,
        (val) => (textOptions.color = val)
    );

    defineExpose({ getAnimation, show });
</script>
